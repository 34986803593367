<template>
  <div
    :id="id"
    class="card"
    :shift="shift"
    :draggable="draggable"
    @dragstart="dragStart"
    @dragover.stop
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: ['id', 'shift', 'draggable'],
  methods: {
    dragStart(e) {
      const target = e.target
      console.log(JSON.parse(target.getAttribute('shift')))
      e.dataTransfer.setData('card_id', target.id)
      // setTimeout(() => {
      //   target.style.display = 'none'
      // }, 0)
    }
  }
}
</script>
