<template>
  <div :id="id" :class="classes" @dragover="dragOver" @drop="drop">
    <slot />
  </div>
</template>

<script>
export default {
  props: ['id', 'classes', 'dragoverPrevent', 'dropEvent', 'date', 'employee'],
  methods: {
    dragOver(e) {
      //   console.log(this.dragoverPrevent)
      //   console.log(this.dragoverPrevent)
      if (this.dragoverPrevent) {
        e.preventDefault()
      }
    },
    drop(e) {
      if (this.dropEvent) {
        const card_id = e.dataTransfer.getData('card_id')
        if (card_id) {
          const newCard = document.getElementById(card_id).cloneNode(true)
          const board_id = document.getElementById(this.id)

          this.$emit('update', {
            board: board_id,
            card: newCard,
            date: this.date,
            employee: this.employee
          })
          // console.log(JSON.parse(newCard.getAttribute('shift'), board_id))
          // if (board_id.children.length > 0) {
          //   board_id.removeChild(board_id.children[0])
          // }

          // setTimeout(() => {
          //   board_id.appendChild(newCard)
          // }, 0)
          // if (e.target.children.length > 0) {
          //   const oldCard = e.target.children[0]
          //   e.target.replaceChild(newCard, oldCard)
          // } else {
          //   e.target.appendChild(newCard)
          // }
          // //   card.style.display = 'block'
        }
      }
    }
    // remove() {
    //   const board_id = document.getElementById(this.id)
    //   this.$emit('remove', {
    //     board: board_id,
    //     date: this.date,
    //     employee: this.employee
    //   })
    // }
  }
}
</script>
